const ADD_PRODUCT = "ADD_PRODUCT";
const UPDATE_PRODUCT = "ADD_PRODUCT";
const DELETE_PRODUCT = "DELETE_PRODUCT";
const PRODUCTS = "PRODUCTS";
const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR";
const PRODUCT_DETAILS = "PRODUCT_DETAILS";
const POS_PRODUCT = "POS_PRODUCT";

export {
	ADD_PRODUCT,
	UPDATE_PRODUCT,
	DELETE_PRODUCT,
	PRODUCTS,
	ADD_PRODUCT_ERROR,
	PRODUCT_DETAILS,
	POS_PRODUCT,
};
