const ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY";
const UPDATE_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY";
const DELETE_PRODUCT_CATEGORY = "DELETE_PRODUCT_CATEGORY";
const PRODUCT_CATEGORYS = "PRODUCT_CATEGORYS";
const ADD_PRODUCT_CATEGORY_ERROR = "ADD_PRODUCT_CATEGORY_ERROR";
const PRODUCT_CATEGORY_DETAILS = "PRODUCT_CATEGORY_DETAILS";

export {
	ADD_PRODUCT_CATEGORY,
	UPDATE_PRODUCT_CATEGORY,
	DELETE_PRODUCT_CATEGORY,
	PRODUCT_CATEGORYS,
	ADD_PRODUCT_CATEGORY_ERROR,
	PRODUCT_CATEGORY_DETAILS,
};
