const LOGIN_USER = "LOGIN_USER";
const STAFFS = "STAFFS";
const ADD_USER_ERROR = "  ADD_USER_ERROR";
const ADD_STAFF = "ADD_STAFF";
const DETAIL_STAFF = "DETAIL_STAFF";
const DELETE_STAFF = "DELETE_STAFF";

export {
	LOGIN_USER,
	ADD_STAFF,
	STAFFS,
	ADD_USER_ERROR,
	DETAIL_STAFF,
	DELETE_STAFF,
};
